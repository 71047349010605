import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutDefaultComponent } from './layouts/layout-default/layout-default.component';
import { SharedModule } from './shared/shared.module';

const routes: Routes = [
  {
    path: '',
    component: LayoutDefaultComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadChildren: () =>
          import(`./pages/home/home.module`).then((m) => m.HomeModule),
        canActivate: [],
      },
      {
        path: 'home2',
        loadChildren: () =>
          import(`./pages/home2/home2.module`).then((m) => m.Home2Module),
        canActivate: [],
      },
    ],
  },
  {
    path: 'not-found',
    pathMatch: 'full',
    loadChildren: () =>
      import(`./pages/not-found/not-found.module`).then(
        (m) => m.NotFoundModule
      ),
    canActivate: [],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      paramsInheritanceStrategy: 'always',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
  providers: [],
  bootstrap: [],
})
export class AppRoutingModule {}
