<div class="toast__wrapper" *ngIf="toastService.toasts.length">
  <ngb-toast
    *ngFor="let toast of toastService.toasts"
    [header]="toast.header || 'Success!'"
    [class]="toast.className"
    [autoHide]="toast.autoHide || true"
    [delay]="toast.delay || 5000"
    (hidden)="toastService.remove(toast)"
  >
    <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
      <ng-template [ngTemplateOutlet]="toast.textOrTemplate"></ng-template>
    </ng-template>

    <ng-template #text>{{ toast.textOrTemplate }}</ng-template>
  </ngb-toast>
</div>
