import { Component, OnInit, TemplateRef } from '@angular/core';
import { ToastService } from 'src/app/shared/services/components/toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
})
export class ToastComponent implements OnInit {
  constructor(public toastService: ToastService) {}

  ngOnInit(): void {}

  isTemplate(toast: any) {
    return toast.textOrTemplate instanceof TemplateRef;
  }
}
