import { Injectable, TemplateRef } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  toasts: any[] = [];

  constructor() {}

  show(textOrTemplate: string | TemplateRef<any>, options: object = {}) {
    this.toasts.push({
      textOrTemplate,
      ...options,
    });
  }

  remove(toast: string | object) {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }
}
